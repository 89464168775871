﻿/// <reference path="../main.scss" />

/**
 * ========================================
 * FOOTER
 * ========================================
 */

footer {
	background: $footer-bg-color;
	.footer-widget {
		padding: 15px 0 30px;
		.single-widget {
			h2 {
				color: $gray-lighter;
				font-size: $font-size-large;
				margin-bottom: 22px;
				text-transform: uppercase;
			}
			ul {
				li {
					a {
						color: darken($gray-lighter, 20%);
						padding: 5px 0;
					}
				}
			}
		}
	}
	.footer-bottom {
		background: $footer-copyright-bg-color;
		color: $brand-light-gray;
		font-size: $font-size-small;
		padding: 10px 0;
		.col-sm-4 {
			@media only screen and (max-width: $grid-float-breakpoint-max) {
				text-align: center;
				margin-bottom: 10px;
			}
		}
	}
}
