/// <reference path="../main.scss"/>
ul.minicart-dropdown-menu {
	color: $white;
}

#topcartlink {
	&:hover {
		>ul.minicart-dropdown-menu {
			display: block;
			@include animation(fadeInUp 400ms);
		}
	}
	ul.minicart-dropdown-menu {
		position: absolute;
		//top: 30px;
		left: -50%;
		background: rgba(0, 0, 0, 0.6);
		list-style: none;
		padding: 0;
		margin: 0;
		width: 300px;
		height: 400px;
		overflow-y: hidden;
		-webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
		box-shadow: 0 3px 3px rgba(0, 0, 0, 0.1);
		display: none;
		z-index: 999;
		li {
			padding: 10px 20px 0;
			&:last-child {
				padding-bottom: 20px;
			}
			.active {
				padding-left: 0;
				color: $brand-accent;
			}
			a {
				color: $white;
				background: none;
				&:hover {
					color: $brand-accent;
				}
			}
		}
		.item {
			padding: 0px;
		}
	}
	.fa-angle-down {
		padding-left: 5px;
	}
	.buttons {
		margin-top: 20px;
		.btn {
			width: 100%;
			display: block;
		}
	}
}

@keyframes fadeInUp {
	0% {
		opacity: 0;
		-webkit-transform: translateY(20px);
		transform: translateY(20px);
	}
	100% {
		opacity: 1;
		-webkit-transform: translateY(0);
		transform: translateY(0);
	}
}
