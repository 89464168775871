.product-carousel {
	.slick-arrow {
		&:before {
			color: $brand-accent;
		}
	}
	.slick-dots {
		line-height: 0!important;
		margin-left: 0;
		position: relative;
		bottom: 1px;
		margin-top: 15px;
		li {
			border: 1px solid $brand-accent;
			border-radius: 12px;
			height: 10px;
			width: 10px;
			display: inline-block;
			button {
				height: 11px;
			}
			&.slick-active {
				background: $brand-accent;
			}
		}
	}
}
