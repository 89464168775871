//==================================================================================================//
// Site                                                                                             //
//==================================================================================================//
$brand-accent: #e2231a;
$brand-dark-gray: #231f20;
$brand-light-gray: #626366;
$white: #ffffff;
$font-path: "../fonts/";
$image-path: "../images/";
@function image-url($url) {
	@return url($image-path + $url);
}

$slick-font-path: "#{$font-path}/";
$slick-font-family: "slick";
$slick-loader-path: "../images";
$slick-arrow-color:$brand-accent;
$slick-dot-color:transparent;
$slick-dot-color-active: $slick-dot-color;
$slick-prev-character: "\2190";
$slick-next-character: "\2192";
$slick-dot-character: "\2022";
$slick-dot-size: 6px;
$slick-opacity-default: 0.75;
$slick-opacity-on-hover: 1;
$slick-opacity-not-active: 0.25;
$fa-font-path: $font-path;
$font-family-sans-serif: "Open Sans",
"Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$font-family-accent: "Montserrat",
$font-family-sans-serif;
$footer-bg-color: $brand-light-gray;
$footer-copyright-bg-color: $brand-dark-gray;
//==================================================================================================//
// Animate.sass                                                                                     //
//==================================================================================================//
/////////////////////////////////////////////////////////
// Animation Default Settings                          //
/////////////////////////////////////////////////////////
// Base module settings
$base-duration: 1s !default; // used in 1-base module
$base-origin: top center !default;
$base-degrees: 30deg !default; // used in lightspeed & special modules
$base-fill-mode: both !default;
$base-iteration-count: infinite !default;
$base-timing-function-in: ease-in !default;
$base-timing-function-out: ease-out !default;
$base-timing-function-in-out: ease-in-out !default;
$base-distance: 10px !default; // used in fade, shake and bounce modules
$base-distance-big: 1000px !default; // used in fadeBig and bounce modules
// Rotate module settings
$rotate-degrees: 90deg !default;
$rotate-degrees-max: 200deg !default;
$rotate-origin: center center !default;
// Flipper module settings
$flipper-perspective: 400px !default;
$flipper-translate: 150px !default;
$flipper-degrees: 10deg !default;
$flipper-degrees-max: 90deg !default;
// Hinge module settings
$hinge-origin: top left !default;
$hinge-degrees-max: 80deg !default;
$hinge-translate: 700px !default;
//==================================================================================================//
// Module loading                                                                                   //
//==================================================================================================//
// To include an animation module in the compiled css file, simply set it's attribute below to true //
// (all modules set to false will not be included)                                                  //
// The idea here is to only load the animations you want to use on a per project basis              //
// This helps keep the compiled css file at its leanest                                             //
//==================================================================================================//
// Hugely influenced and inspired by @csswizardry's inuit.css _vars.scss file                       //
//==================================================================================================//
// Fade Enter
$use-fadeIn: true;
$use-fadeInUp: true;
$use-fadeInUpBig: true;
$use-fadeInDown: true;
$use-fadeInDownBig: true;
$use-fadeInLeft: true;
$use-fadeInLeftBig: true;
$use-fadeInRight: true;
$use-fadeInRightBig: true;
// Fade Exit
$use-fadeOut: true;
$use-fadeOutUp: true;
$use-fadeOutUpBig: true;
$use-fadeOutDown: true;
$use-fadeOutDownBig: true;
$use-fadeOutLeft: true;
$use-fadeOutLeftBig: true;
$use-fadeOutRight: true;
$use-fadeOutRightBig: true;
// Bounce Enter
$use-bounceIn: true;
$use-bounceInUp: true;
$use-bounceInDown: true;
$use-bounceInLeft: true;
$use-bounceInRight: true;
// Bounce Exit
$use-bounceOut: true;
$use-bounceOutUp: true;
$use-bounceOutDown: true;
$use-bounceOutLeft: true;
$use-bounceOutRight: true;
// Rotate Enter
$use-rotateIn: true;
$use-rotateInUpLeft: true;
$use-rotateInUpRight: true;
$use-rotateInDownLeft: true;
$use-rotateInDownRight: true;
// Rotate Exit
$use-rotateOut: true;
$use-rotateOutUpLeft: true;
$use-rotateOutUpRight: true;
$use-rotateOutDownLeft: true;
$use-rotateOutDownRight: true;
// Slide Enter
$use-slideIn: true;
$use-slideInUp: true;
$use-slideInDown: true;
$use-slideInLeft: true;
$use-slideInRight: true;
// Slide Exit
$use-slideOut: true;
$use-slideOutUp: true;
$use-slideOutDown: true;
$use-slideOutLeft: true;
$use-slideOutRight: true;
// Flippers
$use-flip: true;
$use-flipInX: true;
$use-flipInY: true;
$use-flipOutX: true;
$use-flipOutY: true;
// Lightspeed
$use-lightSpeedIn: true;
$use-lightSpeedOut: true;
// Attention Seekers
$use-bounce: true;
$use-flash: true;
$use-pulse: true;
$use-wiggle: true;
$use-swing: true;
$use-shake: true;
$use-tada: true;
$use-wobble: true;
// Special
$use-hinge: true;
$use-rollIn: true;
$use-rollOut: true;
// Zoom In
$use-zoomIn: true;
$use-zoomInDown: true;
$use-zoomInLeft: true;
$use-zoomInRight: true;
$use-zoomInUp: true;
// Zoom Out
$use-zoomOut: true;
$use-zoomOutDown: true;
$use-zoomOutLeft: true;
$use-zoomOutRight: true;
$use-zoomOutUp: true;
//==================================================================================================//
// Bootstrap                                                                                        //
//==================================================================================================//
$bootstrap-sass-asset-helper: false !default;
//
// Variables
// --------------------------------------------------
//== Colors
//
//## Gray and brand colors for use across Bootstrap.
$gray-base: #000 !default;
$gray-darker: lighten($gray-base, 13.5%) !default; // #222
$gray-dark: lighten($gray-base, 20%) !default; // #333
$gray: lighten($gray-base, 33.5%) !default; // #555
$gray-light: lighten($gray-base, 46.7%) !default; // #777
$gray-lighter: lighten($gray-base, 93.5%) !default; // #eee
$brand-primary: $brand-accent !default; // #337ab7
$brand-success: #5cb85c !default;
$brand-info: #5bc0de !default;
$brand-warning: #f0ad4e !default;
$brand-danger: #d9534f !default;
//== Scaffolding
//
//## Settings for some of the most global styles.
//** Background color for `<body>`.
$body-bg: #fff !default;
//** Global text color on `<body>`.
$text-color: $gray-dark !default;
//** Global textual link color.
$link-color: $brand-primary !default;
//** Link hover color set via `darken()` function.
$link-hover-color: darken($link-color, 15%) !default;
//** Link hover decoration.
$link-hover-decoration: underline !default;
//== Typography
//
//## Font, line-height, and color for body text, headings, and more.
$font-family-sans-serif: "Helvetica Neue",
Helvetica,
Arial,
sans-serif !default;
$font-family-serif: Georgia,
"Times New Roman",
Times,
serif !default;
//** Default monospace fonts for `<code>`, `<kbd>`, and `<pre>`.
$font-family-monospace: Menlo,
Monaco,
Consolas,
"Courier New",
monospace !default;
$font-family-base: $font-family-sans-serif !default;
$font-size-base: 14px !default;
$font-size-large: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-small: ceil(($font-size-base * 0.85)) !default; // ~12px
$font-size-h1: floor(($font-size-base * 2.6)) !default; // ~36px
$font-size-h2: floor(($font-size-base * 2.15)) !default; // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)) !default; // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)) !default; // ~18px
$font-size-h5: $font-size-base !default;
$font-size-h6: ceil(($font-size-base * 0.85)) !default; // ~12px
//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429 !default; // 20/14
//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)) !default; // ~20px
//** By default, this inherits from the `<body>`.
$headings-font-family: inherit !default;
$headings-font-weight: 500 !default;
$headings-line-height: 1.1 !default;
$headings-color: inherit !default;
//== Iconography
//
//## Specify custom location and filename of the included Glyphicons icon font. Useful for those including Bootstrap via Bower.
//** Load fonts from this directory.
// [converter] If $bootstrap-sass-asset-helper if used, provide path relative to the assets load path.
// [converter] This is because some asset helpers, such as Sprockets, do not work with file-relative paths.
$icon-font-path: if($bootstrap-sass-asset-helper, "bootstrap/", "../fonts/bootstrap/") !default;
//** File name for all font files.
$icon-font-name: "glyphicons-halflings-regular" !default;
//** Element ID within SVG icon file.
$icon-font-svg-id: "glyphicons_halflingsregular" !default;
//== Components
//
//## Define common padding and border radius sizes and more. Values based on 14px text and 1.428 line-height (~20px to start).
$padding-base-vertical: 6px !default;
$padding-base-horizontal: 12px !default;
$padding-large-vertical: 10px !default;
$padding-large-horizontal: 16px !default;
$padding-small-vertical: 5px !default;
$padding-small-horizontal: 10px !default;
$padding-xs-vertical: 1px !default;
$padding-xs-horizontal: 5px !default;
$line-height-large: 1.3333333 !default; // extra decimals for Win 8.1 Chrome
$line-height-small: 1.5 !default;
$border-radius-base: 0px !default;
$border-radius-large: 6px !default;
$border-radius-small: 3px !default;
//** Global color for active items (e.g., navs or dropdowns).
$component-active-color: #fff !default;
//** Global background color for active items (e.g., navs or dropdowns).
$component-active-bg: $brand-primary !default;
//** Width of the `border` for generating carets that indicate dropdowns.
$caret-width-base: 4px !default;
//** Carets increase slightly in size for larger components.
$caret-width-large: 5px !default;
//== Tables
//
//## Customizes the `.table` component with basic values, each used across all table variations.
//** Padding for `<th>`s and `<td>`s.
$table-cell-padding: 8px !default;
//** Padding for cells in `.table-condensed`.
$table-condensed-cell-padding: 5px !default;
//** Default background color used for all tables.
$table-bg: transparent !default;
//** Background color used for `.table-striped`.
$table-bg-accent: #f9f9f9 !default;
//** Background color used for `.table-hover`.
$table-bg-hover: #f5f5f5 !default;
$table-bg-active: $table-bg-hover !default;
//** Border color for table and cell borders.
$table-border-color: #ddd !default;
//== Buttons
//
//## For each of Bootstrap's buttons, define text, background and border color.
$btn-font-weight: normal !default;
$btn-default-color: #333 !default;
$btn-default-bg: #fff !default;
$btn-default-border: #ccc !default;
$btn-primary-color: #fff !default;
$btn-primary-bg: $brand-primary !default;
$btn-primary-border: darken($btn-primary-bg, 5%) !default;
$btn-success-color: #fff !default;
$btn-success-bg: $brand-success !default;
$btn-success-border: darken($btn-success-bg, 5%) !default;
$btn-info-color: #fff !default;
$btn-info-bg: $brand-info !default;
$btn-info-border: darken($btn-info-bg, 5%) !default;
$btn-warning-color: #fff !default;
$btn-warning-bg: $brand-warning !default;
$btn-warning-border: darken($btn-warning-bg, 5%) !default;
$btn-danger-color: #fff !default;
$btn-danger-bg: $brand-danger !default;
$btn-danger-border: darken($btn-danger-bg, 5%) !default;
$btn-link-disabled-color: $gray-light !default;
// Allows for customizing button radius independently from global border radius
$btn-border-radius-base: $border-radius-base !default;
$btn-border-radius-large: $border-radius-large !default;
$btn-border-radius-small: $border-radius-small !default;
//== Forms
//
//##
//** `<input>` background color
$input-bg: #fff !default;
//** `<input disabled>` background color
$input-bg-disabled: $gray-lighter !default;
//** Text color for `<input>`s
$input-color: $gray !default;
//** `<input>` border color
$input-border: #ccc !default;
// TODO: Rename `$input-border-radius` to `$input-border-radius-base` in v4
//** Default `.form-control` border radius
// This has no effect on `<select>`s in some browsers, due to the limited stylability of `<select>`s in CSS.
$input-border-radius: $border-radius-base !default;
//** Large `.form-control` border radius
$input-border-radius-large: $border-radius-large !default;
//** Small `.form-control` border radius
$input-border-radius-small: $border-radius-small !default;
//** Border color for inputs on focus
$input-border-focus: #66afe9 !default;
//** Placeholder text color
$input-color-placeholder: #999 !default;
//** Default `.form-control` height
$input-height-base: ($line-height-computed + ($padding-base-vertical * 2) + 2) !default;
//** Large `.form-control` height
$input-height-large: (ceil($font-size-large * $line-height-large) + ($padding-large-vertical * 2) + 2) !default;
//** Small `.form-control` height
$input-height-small: (floor($font-size-small * $line-height-small) + ($padding-small-vertical * 2) + 2) !default;
//** `.form-group` margin
$form-group-margin-bottom: 15px !default;
$legend-color: $gray-dark !default;
$legend-border-color: #e5e5e5 !default;
//** Background color for textual input addons
$input-group-addon-bg: $gray-lighter !default;
//** Border color for textual input addons
$input-group-addon-border-color: $input-border !default;
//** Disabled cursor for form controls and buttons.
$cursor-disabled: not-allowed !default;
//== Dropdowns
//
//## Dropdown menu container and contents.
//** Background for the dropdown menu.
$dropdown-bg: #fff !default;
//** Dropdown menu `border-color`.
$dropdown-border: rgba(0, 0, 0, .15) !default;
//** Dropdown menu `border-color` **for IE8**.
$dropdown-fallback-border: #ccc !default;
//** Divider color for between dropdown items.
$dropdown-divider-bg: #e5e5e5 !default;
//** Dropdown link text color.
$dropdown-link-color: $gray-dark !default;
//** Hover color for dropdown links.
$dropdown-link-hover-color: darken($gray-dark, 5%) !default;
//** Hover background for dropdown links.
$dropdown-link-hover-bg: #f5f5f5 !default;
//** Active dropdown menu item text color.
$dropdown-link-active-color: $component-active-color !default;
//** Active dropdown menu item background color.
$dropdown-link-active-bg: $component-active-bg !default;
//** Disabled dropdown menu item background color.
$dropdown-link-disabled-color: $gray-light !default;
//** Text color for headers within dropdown menus.
$dropdown-header-color: $gray-light !default;
//** Deprecated `$dropdown-caret-color` as of v3.1.0
$dropdown-caret-color: #000 !default;
//-- Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.
//
// Note: These variables are not generated into the Customizer.
$zindex-navbar: 1000 !default;
$zindex-dropdown: 1000 !default;
$zindex-popover: 1060 !default;
$zindex-tooltip: 1070 !default;
$zindex-navbar-fixed: 1030 !default;
$zindex-modal-background: 1040 !default;
$zindex-modal: 1050 !default;
//== Media queries breakpoints
//
//## Define the breakpoints at which your layout will change, adapting to different screen sizes.
// Extra small screen / phone
//** Deprecated `$screen-xs` as of v3.0.1
$screen-xs: 480px !default;
//** Deprecated `$screen-xs-min` as of v3.2.0
$screen-xs-min: $screen-xs !default;
//** Deprecated `$screen-phone` as of v3.0.1
$screen-phone: $screen-xs-min !default;
// Small screen / tablet
//** Deprecated `$screen-sm` as of v3.0.1
$screen-sm: 768px !default;
$screen-sm-min: $screen-sm !default;
//** Deprecated `$screen-tablet` as of v3.0.1
$screen-tablet: $screen-sm-min !default;
// Medium screen / desktop
//** Deprecated `$screen-md` as of v3.0.1
$screen-md: 992px !default;
$screen-md-min: $screen-md !default;
//** Deprecated `$screen-desktop` as of v3.0.1
$screen-desktop: $screen-md-min !default;
// Large screen / wide desktop
//** Deprecated `$screen-lg` as of v3.0.1
$screen-lg: 1200px !default;
$screen-lg-min: $screen-lg !default;
//** Deprecated `$screen-lg-desktop` as of v3.0.1
$screen-lg-desktop: $screen-lg-min !default;
// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1) !default;
$screen-sm-max: ($screen-md-min - 1) !default;
$screen-md-max: ($screen-lg-min - 1) !default;
//== Grid system
//
//## Define your custom responsive grid.
//** Number of columns in the grid.
$grid-columns: 12 !default;
//** Padding between columns. Gets divided in half for the left and right.
$grid-gutter-width: 30px !default;
// Navbar collapse
//** Point at which the navbar becomes uncollapsed.
$grid-float-breakpoint: $screen-sm-min !default;
//** Point at which the navbar begins collapsing.
$grid-float-breakpoint-max: ($grid-float-breakpoint - 1) !default;
//== Container sizes
//
//## Define the maximum width of `.container` for different screen sizes.
// Small screen / tablet
$container-tablet: (720px + $grid-gutter-width) !default;
//** For `$screen-sm-min` and up.
$container-sm: $container-tablet !default;
// Medium screen / desktop
$container-desktop: (940px + $grid-gutter-width) !default;
//** For `$screen-md-min` and up.
$container-md: $container-desktop !default;
// Large screen / wide desktop
$container-large-desktop: (1140px + $grid-gutter-width) !default;
//** For `$screen-lg-min` and up.
$container-lg: $container-large-desktop !default;
//== Navbar
//
//##
// Basics of a navbar
$navbar-height: 50px !default;
$navbar-margin-bottom: $line-height-computed !default;
$navbar-border-radius: $border-radius-base !default;
$navbar-padding-horizontal: floor(($grid-gutter-width / 2)) !default;
$navbar-padding-vertical: (($navbar-height - $line-height-computed) / 2) !default;
$navbar-collapse-max-height: 340px !default;
$navbar-default-color: #777 !default;
$navbar-default-bg: #f8f8f8 !default;
$navbar-default-border: darken($navbar-default-bg, 6.5%) !default;
// Navbar links
$navbar-default-link-color: #777 !default;
$navbar-default-link-hover-color: #333 !default;
$navbar-default-link-hover-bg: transparent !default;
$navbar-default-link-active-color: #555 !default;
$navbar-default-link-active-bg: darken($navbar-default-bg, 6.5%) !default;
$navbar-default-link-disabled-color: #ccc !default;
$navbar-default-link-disabled-bg: transparent !default;
// Navbar brand label
$navbar-default-brand-color: $navbar-default-link-color !default;
$navbar-default-brand-hover-color: darken($navbar-default-brand-color, 10%) !default;
$navbar-default-brand-hover-bg: transparent !default;
// Navbar toggle
$navbar-default-toggle-hover-bg: #ddd !default;
$navbar-default-toggle-icon-bar-bg: #888 !default;
$navbar-default-toggle-border-color: #ddd !default;
//=== Inverted navbar
// Reset inverted navbar basics
$navbar-inverse-color: lighten($gray-light, 15%) !default;
$navbar-inverse-bg: #222 !default;
$navbar-inverse-border: darken($navbar-inverse-bg, 10%) !default;
// Inverted navbar links
$navbar-inverse-link-color: lighten($gray-light, 15%) !default;
$navbar-inverse-link-hover-color: #fff !default;
$navbar-inverse-link-hover-bg: transparent !default;
$navbar-inverse-link-active-color: $navbar-inverse-link-hover-color !default;
$navbar-inverse-link-active-bg: darken($navbar-inverse-bg, 10%) !default;
$navbar-inverse-link-disabled-color: #444 !default;
$navbar-inverse-link-disabled-bg: transparent !default;
// Inverted navbar brand label
$navbar-inverse-brand-color: $navbar-inverse-link-color !default;
$navbar-inverse-brand-hover-color: #fff !default;
$navbar-inverse-brand-hover-bg: transparent !default;
// Inverted navbar toggle
$navbar-inverse-toggle-hover-bg: #333 !default;
$navbar-inverse-toggle-icon-bar-bg: #fff !default;
$navbar-inverse-toggle-border-color: #333 !default;
//== Navs
//
//##
//=== Shared nav styles
$nav-link-padding: 10px 15px !default;
$nav-link-hover-bg: $body-bg !default;
$nav-disabled-link-color: $gray-light !default;
$nav-disabled-link-hover-color: $gray-light !default;
//== Tabs
$nav-tabs-border-color:$gray-lighter;
$nav-tabs-link-hover-border-color: transparent;
$nav-tabs-active-link-hover-bg: $body-bg !default;
$nav-tabs-active-link-hover-color: $brand-accent !default;
$nav-tabs-active-link-hover-border-color: transparent;
$nav-tabs-justified-link-border-color: $gray-lighter !default;
$nav-tabs-justified-active-link-border-color: $brand-accent !default;
//== Pills
$nav-pills-border-radius: $border-radius-base !default;
$nav-pills-active-link-hover-bg: $body-bg !default;
$nav-pills-active-link-hover-color: $component-active-color !default;
//== Pagination
//
//##
$pagination-color: $link-color !default;
$pagination-bg: #fff !default;
$pagination-border: #ddd !default;
$pagination-hover-color: $link-hover-color !default;
$pagination-hover-bg: $gray-lighter !default;
$pagination-hover-border: #ddd !default;
$pagination-active-color: #fff !default;
$pagination-active-bg: $brand-primary !default;
$pagination-active-border: $brand-primary !default;
$pagination-disabled-color: $gray-light !default;
$pagination-disabled-bg: #fff !default;
$pagination-disabled-border: #ddd !default;
//== Pager
//
//##
$pager-bg: $pagination-bg !default;
$pager-border: $pagination-border !default;
$pager-border-radius: 15px !default;
$pager-hover-bg: $pagination-hover-bg !default;
$pager-active-bg: $pagination-active-bg !default;
$pager-active-color: $pagination-active-color !default;
$pager-disabled-color: $pagination-disabled-color !default;
//== Jumbotron
//
//##
$jumbotron-padding: 30px !default;
$jumbotron-color: inherit !default;
$jumbotron-bg: $gray-lighter !default;
$jumbotron-heading-color: inherit !default;
$jumbotron-font-size: ceil(($font-size-base * 1.5)) !default;
$jumbotron-heading-font-size: ceil(($font-size-base * 4.5)) !default;
//== Form states and alerts
//
//## Define colors for form feedback states and, by default, alerts.
$state-success-text: #3c763d !default;
$state-success-bg: #dff0d8 !default;
$state-success-border: darken(adjust-hue($state-success-bg, -10), 5%) !default;
$state-info-text: #31708f !default;
$state-info-bg: #d9edf7 !default;
$state-info-border: darken(adjust-hue($state-info-bg, -10), 7%) !default;
$state-warning-text: #8a6d3b !default;
$state-warning-bg: #fcf8e3 !default;
$state-warning-border: darken(adjust-hue($state-warning-bg, -10), 5%) !default;
$state-danger-text: #a94442 !default;
$state-danger-bg: #f2dede !default;
$state-danger-border: darken(adjust-hue($state-danger-bg, -10), 5%) !default;
//== Tooltips
//
//##
//** Tooltip max width
$tooltip-max-width: 200px !default;
//** Tooltip text color
$tooltip-color: #fff !default;
//** Tooltip background color
$tooltip-bg: #000 !default;
$tooltip-opacity: .9 !default;
//** Tooltip arrow width
$tooltip-arrow-width: 5px !default;
//** Tooltip arrow color
$tooltip-arrow-color: $tooltip-bg !default;
//== Popovers
//
//##
//** Popover body background color
$popover-bg: #fff !default;
//** Popover maximum width
$popover-max-width: 276px !default;
//** Popover border color
$popover-border-color: rgba(0, 0, 0, .2) !default;
//** Popover fallback border color
$popover-fallback-border-color: #ccc !default;
//** Popover title background color
$popover-title-bg: darken($popover-bg, 3%) !default;
//** Popover arrow width
$popover-arrow-width: 10px !default;
//** Popover arrow color
$popover-arrow-color: $popover-bg !default;
//** Popover outer arrow width
$popover-arrow-outer-width: ($popover-arrow-width + 1) !default;
//** Popover outer arrow color
$popover-arrow-outer-color: fade_in($popover-border-color, 0.05) !default;
//** Popover outer arrow fallback color
$popover-arrow-outer-fallback-color: darken($popover-fallback-border-color, 20%) !default;
//== Labels
//
//##
//** Default label background color
$label-default-bg: $gray-light !default;
//** Primary label background color
$label-primary-bg: $brand-primary !default;
//** Success label background color
$label-success-bg: $brand-success !default;
//** Info label background color
$label-info-bg: $brand-info !default;
//** Warning label background color
$label-warning-bg: $brand-warning !default;
//** Danger label background color
$label-danger-bg: $brand-danger !default;
//** Default label text color
$label-color: #fff !default;
//** Default text color of a linked label
$label-link-hover-color: #fff !default;
//== Modals
//
//##
//** Padding applied to the modal body
$modal-inner-padding: 15px !default;
//** Padding applied to the modal title
$modal-title-padding: 15px !default;
//** Modal title line-height
$modal-title-line-height: $line-height-base !default;
//** Background color of modal content area
$modal-content-bg: #fff !default;
//** Modal content border color
$modal-content-border-color: rgba(0, 0, 0, .2) !default;
//** Modal content border color **for IE8**
$modal-content-fallback-border-color: #999 !default;
//** Modal backdrop background color
$modal-backdrop-bg: #000 !default;
//** Modal backdrop opacity
$modal-backdrop-opacity: .5 !default;
//** Modal header border color
$modal-header-border-color: #e5e5e5 !default;
//** Modal footer border color
$modal-footer-border-color: $modal-header-border-color !default;
$modal-lg: 900px !default;
$modal-md: 600px !default;
$modal-sm: 300px !default;
//== Alerts
//
//## Define alert colors, border radius, and padding.
$alert-padding: 15px !default;
$alert-border-radius: $border-radius-base !default;
$alert-link-font-weight: bold !default;
$alert-success-bg: $state-success-bg !default;
$alert-success-text: $state-success-text !default;
$alert-success-border: $state-success-border !default;
$alert-info-bg: $state-info-bg !default;
$alert-info-text: $state-info-text !default;
$alert-info-border: $state-info-border !default;
$alert-warning-bg: $state-warning-bg !default;
$alert-warning-text: $state-warning-text !default;
$alert-warning-border: $state-warning-border !default;
$alert-danger-bg: $state-danger-bg !default;
$alert-danger-text: $state-danger-text !default;
$alert-danger-border: $state-danger-border !default;
//== Progress bars
//
//##
//** Background color of the whole progress component
$progress-bg: #f5f5f5 !default;
//** Progress bar text color
$progress-bar-color: #fff !default;
//** Variable for setting rounded corners on progress bar.
$progress-border-radius: $border-radius-base !default;
//** Default progress bar color
$progress-bar-bg: $brand-primary !default;
//** Success progress bar color
$progress-bar-success-bg: $brand-success !default;
//** Warning progress bar color
$progress-bar-warning-bg: $brand-warning !default;
//** Danger progress bar color
$progress-bar-danger-bg: $brand-danger !default;
//** Info progress bar color
$progress-bar-info-bg: $brand-info !default;
//== List group
//
//##
//** Background color on `.list-group-item`
$list-group-bg: #fff !default;
//** `.list-group-item` border color
$list-group-border: #ddd !default;
//** List group border radius
$list-group-border-radius: $border-radius-base !default;
//** Background color of single list items on hover
$list-group-hover-bg: #f5f5f5 !default;
//** Text color of active list items
$list-group-active-color: $component-active-color !default;
//** Background color of active list items
$list-group-active-bg: $component-active-bg !default;
//** Border color of active list elements
$list-group-active-border: $list-group-active-bg !default;
//** Text color for content within active list items
$list-group-active-text-color: lighten($list-group-active-bg, 40%) !default;
//** Text color of disabled list items
$list-group-disabled-color: $gray-light !default;
//** Background color of disabled list items
$list-group-disabled-bg: $gray-lighter !default;
//** Text color for content within disabled list items
$list-group-disabled-text-color: $list-group-disabled-color !default;
$list-group-link-color: #555 !default;
$list-group-link-hover-color: $list-group-link-color !default;
$list-group-link-heading-color: #333 !default;
//== Panels
//
//##
$panel-bg: #fff !default;
$panel-body-padding: 15px !default;
$panel-heading-padding: 10px 15px !default;
$panel-footer-padding: $panel-heading-padding !default;
$panel-border-radius: $border-radius-base !default;
//** Border color for elements within panels
$panel-inner-border: #ddd !default;
$panel-footer-bg: #f5f5f5 !default;
$panel-default-text: $gray-dark !default;
$panel-default-border: #ddd !default;
$panel-default-heading-bg: #f5f5f5 !default;
$panel-primary-text: #fff !default;
$panel-primary-border: $brand-primary !default;
$panel-primary-heading-bg: $brand-primary !default;
$panel-success-text: $state-success-text !default;
$panel-success-border: $state-success-border !default;
$panel-success-heading-bg: $state-success-bg !default;
$panel-info-text: $state-info-text !default;
$panel-info-border: $state-info-border !default;
$panel-info-heading-bg: $state-info-bg !default;
$panel-warning-text: $state-warning-text !default;
$panel-warning-border: $state-warning-border !default;
$panel-warning-heading-bg: $state-warning-bg !default;
$panel-danger-text: $state-danger-text !default;
$panel-danger-border: $state-danger-border !default;
$panel-danger-heading-bg: $state-danger-bg !default;
//== Thumbnails
//
//##
//** Padding around the thumbnail image
$thumbnail-padding: 4px !default;
//** Thumbnail background color
$thumbnail-bg: $body-bg !default;
//** Thumbnail border color
$thumbnail-border: #ddd !default;
//** Thumbnail border radius
$thumbnail-border-radius: $border-radius-base !default;
//** Custom text color for thumbnail captions
$thumbnail-caption-color: $text-color !default;
//** Padding around the thumbnail caption
$thumbnail-caption-padding: 9px !default;
//== Wells
//
//##
$well-bg: #f5f5f5 !default;
$well-border: darken($well-bg, 7%) !default;
//== Badges
//
//##
$badge-color: #fff !default;
//** Linked badge text color on hover
$badge-link-hover-color: #fff !default;
$badge-bg: $gray-light !default;
//** Badge text color in active nav link
$badge-active-color: $link-color !default;
//** Badge background color in active nav link
$badge-active-bg: #fff !default;
$badge-font-weight: bold !default;
$badge-line-height: 1 !default;
$badge-border-radius: 10px !default;
//== Breadcrumbs
//
//##
$breadcrumb-padding-vertical: 8px !default;
$breadcrumb-padding-horizontal: 15px !default;
//** Breadcrumb background color
$breadcrumb-bg: #f5f5f5 !default;
//** Breadcrumb text color
$breadcrumb-color: #ccc !default;
//** Text color of current page in the breadcrumb
$breadcrumb-active-color: $gray-light !default;
//** Textual separator for between breadcrumb elements
$breadcrumb-separator: "/" !default;
//== Carousel
//
//##
$carousel-text-shadow: 0 1px 2px rgba(0, 0, 0, .6) !default;
$carousel-control-color: #fff !default;
$carousel-control-width: 15% !default;
$carousel-control-opacity: .5 !default;
$carousel-control-font-size: 20px !default;
$carousel-indicator-active-bg: #fff !default;
$carousel-indicator-border-color: #fff !default;
$carousel-caption-color: #fff !default;
//== Close
//
//##
$close-font-weight: bold !default;
$close-color: #000 !default;
$close-text-shadow: 0 1px 0 #fff !default;
//== Code
//
//##
$code-color: #c7254e !default;
$code-bg: #f9f2f4 !default;
$kbd-color: #fff !default;
$kbd-bg: #333 !default;
$pre-bg: #f5f5f5 !default;
$pre-color: $gray-dark !default;
$pre-border-color: #ccc !default;
$pre-scrollable-max-height: 340px !default;
//== Type
//
//##
//** Horizontal offset for forms and lists.
$component-offset-horizontal: 180px !default;
//** Text muted color
$text-muted: $gray-light !default;
//** Abbreviations and acronyms border color
$abbr-border-color: $gray-light !default;
//** Headings small color
$headings-small-color: $gray-light !default;
//** Blockquote small color
$blockquote-small-color: $gray-light !default;
//** Blockquote font size
$blockquote-font-size: ($font-size-base * 1.25) !default;
//** Blockquote border color
$blockquote-border-color: $gray-lighter !default;
//** Page header border color
$page-header-border-color: $gray-lighter !default;
//** Width of horizontal description list titles
$dl-horizontal-offset: $component-offset-horizontal !default;
//** Point at which .dl-horizontal becomes horizontal
$dl-horizontal-breakpoint: $grid-float-breakpoint !default;
//** Horizontal line color.
$hr-border: $gray-lighter !default;
