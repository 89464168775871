/// <reference path="../../bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss"/>
$icon-font-path: '../fonts/';
@import "global/_variables.scss";
// bower:scss
@import "bower_components/bootstrap-sass/assets/stylesheets/_bootstrap.scss";
@import "bower_components/font-awesome/scss/font-awesome.scss";
@import "bower_components/simple-line-icons/scss/simple-line-icons.scss";
@import "bower_components/slick-carousel/slick/slick.scss";
@import "bower_components/slick-carousel/slick/slick-theme.scss";
@import "bower_components/animate-sass/_animate.scss";
// endbower
@import "theme";
@import "red";
@import "components/mini-cart";
@import "components/footer";
@import "components/progress-bar";
@import "layouts/product";
.browserupgrade {
	margin: 0.2em 0;
	background: #ccc;
	color: #000;
	padding: 0.2em 0;
}

.feature-icon-list {
	list-style: none;
	padding: 0;
}

.feature-icon-list li {
	position: relative;
	padding: 20px 0 20px 95px;
}

.feature-icon-list-icon {
	position: absolute;
	top: 0;
	left: 0;
	margin: 20px 0 0 0;
	width: 70px;
	height: 70px;
	border-radius: 50%;
	font-size: 32px;
	line-height: 70px;
	vertical-align: middle;
	text-align: center;
	color: #fff;
}

.feature-icon-list-icon:not(.fa) {
	font-family: "Montserrat", sans-serif;
	font-weight: bold;
}

.section-accent .feature-icon-list-icon {
	background-color: #444;
}

.feature-icon-list-title {
	margin: 0 0 10px;
	font-family: "Montserrat", sans-serif;
	font-size: 14px;
	letter-spacing: 0.05em;
	text-transform: uppercase;
}

.wow {
	visibility: hidden;
}

.fa-inline {
	display: inline-block;
	list-style: none;
	li {
		display: inline;
	}
}

#topcartlink {
	a {
		position: relative;
	}
	.cart-badge {
		background-color: transparent;
		//position: absolute;
		top: 0;
		right: 0;
		vertical-align: top;
	}
}

.product-price-wrapper {
	text-align: center;
	.today-price-wrapper {
		.price {
			color: $brand-accent;
		}
	}
}

.no-wrapping {
	white-space: nowrap;
	display: inline-block;
}

.decimal-hidden {
	font-size: 1px;
	opacity: 0;
}

.price-lockup-wrapper {
	display: inline-flex;
	flex-direction: column;
	justify-content: flex-start;
	text-align: center;
	.retail-wrapper {
		padding: 10px 10px 0;
		border-top: 1px solid $gray-lighter;
		.retail-text {
			font-size: 12px;
			margin-right: 5px;
		}
		.dollar-sign {
			top: 0;
			font-size: 100%;
			position: relative;
			vertical-align: baseline;
		}
		.cents {
			top: -.3em;
			font-size: 55%;
			position: relative;
			vertical-align: text-top;
		}
	}
	.price-lockup-small {
		font-size: 24px;
	}
	.m-b-5 {
		margin-bottom: 5px;
	}
	.price-lockup {
		.cost-wrapper {
			display: flex;
			flex-direction: row;
			justify-content: center;
			//font-family: $font-family-accent;
			color: #323232;
			padding: 0;
			line-height: 1em;
			white-space: normal;
			.cost {
				margin-right: 3px;
				display: flex;
				flex-direction: column;
			}
			.dollar-sign,
			.cents {
				top: -.4em;
				font-size: 55%;
				position: relative;
				vertical-align: text-top;
			}
			.cost-header {
				margin-top: -4px;
				font-weight: 700;
				display: block;
				text-align: center;
				font-size: 12px;
				line-height: 12px;
			}
			.cost-sub-header {
				font-weight: 100;
				font-size: 10px;
				line-height: 12px;
			}
			.cost-divider {
				margin-right: 3px;
				display: flex;
				flex-direction: column;
			}
			.text-magenta {
				color: $brand-accent;
			}
		}
	}
}

.product-title {
	display: block;
	margin-bottom: 20px;
	h4 {
		text-align: center;
	}
}

.product-thumbnail-image-container {
	position: relative;
	.product-labels {
		position: absolute;
		top: 0;
		left: 0;
		.label {
			background-color: $brand-accent;
			border-radius: $border-radius-base;
			font-size: $font-size-base;
		}
	}
}

body:not(.home) {
	.container {
		padding-top: 100px;
	}
	.progress-wrap {
		margin-bottom: 30px;
	}
	&.shop {
		.section {
			padding-top: 0;
		}
	}
}

.stuck {
	position: fixed;
	top: 60px;
}

.nav-tabs {
	>li {
		&:not(.active) {
			a {
				color: $text-color;
				&:hover,
				&:focus,
				&:active {
					color: $brand-accent;
				}
			}
		}
	}
}

.nav-tabs-justified {
	border-bottom: 0;
	>li>a {
		// Override margin from .nav-tabs
		margin-right: 0;
		border-radius: $border-radius-base;
	}
	>.active>a,
	>.active>a:hover,
	>.active>a:focus {
		border-color: transparent transparent $brand-accent transparent;
	}
	@media (min-width: $screen-sm-min) {
		>li>a {
			border-bottom: 1px solid $nav-tabs-justified-link-border-color;
			border-radius: $border-radius-base $border-radius-base 0 0;
		}
		>.active>a,
		>.active>a:hover,
		>.active>a:focus {
			border-bottom-color: $nav-tabs-justified-active-link-border-color;
		}
	}
}
